<!-- eslint-disable max-len -->
<template>
  <div class="fixed top-0 left-0 right-0 bottom-0 modal_background flex flex-col justify-start items-center">
    <div class="w-96 flex flex-col justify-center items-center bg-white p-6 rounded-xl m-9">
      <img
        src="https://cdn.parcelex.com.br/assets/v3/abstract/parcelex-nuvemshop-icon.svg"
        alt="Logo Parcelex e Nuvemshop"
      />
      <h3 class="text-xl font-bold mt-4">Aplicativo instalado</h3>

      <span class="middle_text">Agora seu cadastro está em análise</span>

      <px-button kind="primary" class="w-80 mt-6" @click="handleClick">OK</px-button>
    </div>
  </div>
</template>

<script>
import { PxButton } from '.';

export default {
  name: 'AppInstalled',
  metaInfo: {
    title: 'Aplicação Instalada',
  },
  components: {
    PxButton,
  },
  methods: {
    handleClick() {
      this.$emit('closeModal', true);
    },
  },
};
</script>

<style>
.modal_background {
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: hard-light;
}

.middle_text {
  @apply mt-3 text-base font-normal;
  color: #64748B;
}
</style>
