<template>
  <!-- eslint-disable max-len -->
    <template-onboarding
      :steps="onboardingSteps"
      :currentStep="currentOnboardingStep"
    >
      <header class="mb-6">
        <px-icon name="brand-icon-clock.svg" class="w-12 mb-3"/>
        <h1 class="font-bold text-xl mb-3">Cadastro em análise</h1>
      </header>
      <div class="space-y-3">
        <p>
          Obrigado pelo seu cadastro no Parcelex para Negócios
          Sua aplicação foi enviada e será analisada pela nossa equipe.
        </p>
        <p>
          Enquanto isso, pedimos que aguarde. Você receberá uma notificação por email quando a análise for concluída.
        </p>
        <p>
          Caso tenha alguma dúvida, envie uma mensagem para nosso time através do email suporte-b2b@parcelex.com.br
        </p>
      </div>

      <app-installed v-if="openModal" @closeModal="closeModal"/>

    </template-onboarding>
</template>

<script>
import { mapState } from 'vuex';
import { PxIcon } from '@/components/custom';
import TemplateOnboarding from './TemplateOnboarding.vue';
import AppInstalled from '../../../components/custom/AppInstalled.vue';

export default {
  name: 'RegistrationUnderReview',
  metaInfo: {
    title: 'Cadastro em análise',
  },
  components: {
    TemplateOnboarding,
    PxIcon,
    AppInstalled,
  },
  data: () => ({
    currentOnboardingStep: 6.5,
    openModal: false,
  }),
  computed: {
    ...mapState('onboarding', ['onboardingSteps']),
    ...mapState('onboarding', ['retailer']),
    ...mapState('auth', ['uuid']),
  },
  methods: {
    closeModal() {
      this.openModal = false;
    },
  },
  mounted() {
    if (this.retailer.platform === 'Nuvemshop' || this.uuid) {
      this.openModal = true;
    }
  },
};
</script>
